.tidemain{
    margin-top: 29px;
}

.tickicon{
    width:18px;
    height: 13px;
    margin-top: 6px;
    margin-right: 19px;
}


.textmain{
    display: flex;
    margin-bottom: 16px;
    
}

.hometext{
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.25px;
    color: #2F3037
}

.hometext1{
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.25px;
    color: #2F3037;
    margin-left: 35px;
}