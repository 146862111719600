.streamingLoader{
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(52, 52, 52, 0.56);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    z-index: 99;
}

.streamingLoader svg {
    width: 20%;
}


.streamingLoader-txt{
    text-align: center;
    color: #fff;
    margin: 0;
    margin-top: 10px;
}

.greyStyleBackground{
    background-color: #141212a8 !important;
    padding: 20px;
}

.videoScreen{
    height: 100vh !important;
    width: 100vw !important;;
    position: relative !important;
    border-radius: none !important;

}

.localVideo{
    position: absolute !important;
    height: 23vh !important;
    /* height: 100vh !important; */
    width: 40vw !important;
    /* width: 100vw !important; */
    top: 3vh;
    right: 5vw !important;
    z-index: 970;
    border-radius: 1rem;
}

.cameraerror{
    display: flex;
    justify-content: center;
    align-items: center;
    color: red;
    min-height: 100vh;
    flex-direction: column;
}

.Remotevideo{
    position: absolute !important;
    height: 100vh !important;
    width: 100vw !important;
    top: 0px;
    left: 0;
}

.localVideo #us{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.Remotevideo #them{
    width: 100%;
    height: 100%;
    object-fit: cover;
}