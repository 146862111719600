.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99999;
}

.pep-info-btn {
  position: fixed;
  bottom: 10px;
  width: 92%;
}

.modal-container {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  max-width: 500px;
  width: 90%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ddd;
  padding-bottom: 10px;
}
.modal-header h2 {
  margin: 0;
}
.modal-header .close-icon {
  cursor: pointer;
  font-size: 24px;
}

.modal-body {
  margin: 20px 0;
}

.pep-section:first-child {
  padding-bottom: 10px;
  border-bottom: 2px solid rgba(158, 153, 153, 0.5294117647);
  margin-bottom: 10px;
}

.pep-modal-wrapper {
  padding: 0 4%;
  box-sizing: border-box;
  overflow-y: scroll;
  height: 70vh;
  margin: 4% 0 18% 0;
}

.pep-list {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin-top: 10px;
  list-style: none;
}
.pep-list li {
  margin: 5px;
  padding: 5px 10px;
  background-color: #f0f0f0;
  border-radius: 5px;
  flex: none;
}

.info-icon {
  margin-left: 5px;
  font-size: 18px;
  cursor: pointer;
}
.modal-footer {
  display: flex;
  justify-content: flex-end;
}
.modal-footer .button {
  margin-left: 10px;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
.modal-footer .button.primary {
  background-color: #007bff;
  color: white;
}
.modal-footer .button.secondary {
  background-color: #6c757d;
}

@media (max-width: 768px) {
  .modal-container {
    width: 95%;
  }
}/*# sourceMappingURL=styles.css.map */