/* Wrapper for each select element */
.select-wrapper {
  position: relative;
  width: 70%;
}

/* Styling for select element */
.select-wrapper select {
  width: 100%;
  height: 40px;
  padding: 0 30px 0 10px; /* Padding to avoid overlap with custom arrow */
  border: 1.5px solid #000000; /* Border color */
  border-radius: 5px;
  background-color: #ffffff; /* Background color */
  font-weight: 600;
  font-size: 16px;
  color: #000000; /* Text color */
  appearance: none; /* Remove default arrow */
}

/* Custom arrow for the select element */
.select-wrapper::after {
  content: '';
  position: absolute;
  top: 50%;
  right: 15px;
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid #000000; /* Arrow color */
  transform: translateY(-50%);
  pointer-events: none;
}

/* Styling for focused state of select */
.select-wrapper select:focus {
  outline: none;
  border-color: #000000; /* Maintain same border color */
}

/* Styling for each option */
.select-wrapper option {
  padding: 10px;
  font-weight: normal;
}

.occupationalDropDownTc {
  height: 45px;
  border-radius: 8px;
  /* border: 1px solid rgb(25, 41, 214); */
}

.occupationalDropDownTc:focus{
   /* border-color: rgb(25, 41, 214); */
   box-shadow: none;
}


.form-title {
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 15px;
  color: #333;
}

.form-group {
  margin: 10px 0px;
}

.form-label {
  font-weight: 600;
  margin-bottom: 5px;
  color: #0C0C0E;
  display: inline-block;
}

.form-input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  margin-bottom: 5px;
}

.form-select_tide {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  font-size: 16px;
  background-color: white;
  margin-bottom: 5px;
  appearance: none; /* Remove default select arrow on WebKit */
  -moz-appearance: none; /* Remove default select arrow on Firefox */
  -webkit-appearance: none; /* Remove default select arrow on WebKit */
  position: relative;
  border-radius: 8px;
  height: 50px;
}

.form-select_tide:focus {
  outline: none;/* Maintain same border color */
}

.form-dropdown {
  position: relative;
}

.dropdown-arrow {
  position: absolute;
  right: 15px;
  z-index: 999;
  top: 23px;
  pointer-events: none; /* Prevent interfering with input focus */
}

.form-help-text {
  color: #888;
  font-size: 14px;
}

.form-note {
  font-size: 14px;
  color: #555;
  position: absolute;
  bottom: -15px;
  right: 0px;
  padding: 0.3rem 2.5rem;
}

.occupations-dropdown-options-container{
  height: 100vh;
  overflow-y: auto;
  margin-bottom: 65px;
}

