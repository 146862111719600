.text-muted {
    color: #6c757d!important;
}
.mb-0 {
    margin-bottom: 0!important;
}
.poweredby-txt{
    font-size: 12px;
    line-height: 1;
    position: fixed;
    /* position: absolute; */
    bottom: 6px;
    /* right: 6px;   */
    z-index: 999999;
    width: 100%;
    padding: 0px 6px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.poweredby-txt span{
    font-weight: bold;
    text-transform: uppercase;
    color: rgba(0, 0, 0, 0.766);
    letter-spacing: 1px;
    font-size: 14px;
}

.poweredby-txts{
    font-size: 12px;
    line-height: 1;
    position: fixed;
    bottom: -4.5px;
    right: 6px;  
    z-index: 999999;
}
.poweredby-txts span{
    font-weight: bold;
    text-transform: uppercase;
    color: rgba(0, 0, 0, 0.766);
    letter-spacing: 1px;
    font-size: 14px;
}

.network-layer{
    position: fixed;
    top: 4px;
    right: 4px;
    min-width: 80px;
    /* height: 100px; */
    /* background-color: rgba(44, 42, 42, 0.182); */
    border-radius: 8px 0px 8px 8px;
    box-shadow: 4px 4px 15px  rgba(59, 59, 59, 0.205);
    z-index: 99999;
    padding: 0px 8px;
    font-size: 13px;
}

.network-svg{
    animation: networksvg 1.2s linear infinite alternate;
}

@keyframes networksvg {
    0%{
        transform: rotateX(0);
    }
    100%{
        transform: rotatex(-180deg);
    }
}