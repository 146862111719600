:root {
  --primary-color: #1929D6;
  --background-color: #f8f9ff;
  --icon-bg-color: #DDE8FF;
  --icon-border-color: #A4BCFF;
  --heading-color: #0C169A;
}

body {
  background-color: #f8f9ff;
  /* background-color: var(--background-color); */
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif; */
}

.kyc-container {
  max-width: 600px;
  margin: 0 auto;
  padding: 1rem 0 0;
  height: 90vh;
}

.main-heading{
  font-size: 24px
}

.sub-heading{
  font-size: 15px;
  color:var(--heading-color) !important;
}

.accessing-heading{
  font-size: 18px;
}

.icons{
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 10px;
}

.icon-text{
  font-size: 13.5px
}

.requirements-circle {
  width: 45px;
  height: 45px;
  background-color: var(--icon-bg-color);
  border: 1px solid var(--icon-border-color);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}



.requirements-circle i {
  /* color: #1929D6; */
  color: var(--primary-color);
  font-size: 16px;
  display: flex;
  align-items: center;
}

.next-button {
  background-color: var(--primary-color);
  border: none;
  border-radius: 25px;
  padding: 1rem;
  font-size: 1.1rem;
  font-weight: 500;
  width: 100%;
  margin-top: 2rem;
}

.consent-box {
  background-color: white;
  border-radius: 15px;
  padding: 1rem;
  margin-top: 1rem;
  display: flex;
  align-items: flex-start;
  gap: 1rem;
  position: fixed;
  bottom: 13.5%;
  width: 92%;
}

.consent-box label{
  font-size: 14px;
}


.consent-checkbox {
  width: 24px;
  height: 24px;
  border-radius: 4px;
  border: 2px solid var(--primary-color);
}

.terms-link {
  color: var(--primary-color);
  text-decoration: none;
  font-weight: 500;
  font-size:14px;
}

.avatar-container {
  position: relative;
  width: fit-content;
  margin: 2rem auto;
}

.avatar-circle {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: #4CAF50;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  font-weight: 500;
  position: relative;
  z-index: 2;
}

.avatar-image {
  width: 42px;
  height: 42px;
  border-radius: 50%;
  position: absolute;
  right: -20px;
  top: 3px;
  border: 2px solid white;
}