.cos_inputs{
    padding: 1rem 15px;
    display: flex;
    flex-direction: column;
}

.cos_hr{
  margin: 0;
  border: 1px solid #2D3090;
}

.input-container {
    position: relative;
    margin: 22px auto;
    width: 100%;
  }

  .cos_input-label{
    font-size: 10px;
    position: absolute;
    top: 32px;
    left: 0;
  }
  
  .input-container input[type="text"] {
    font-size: 14px;
    width: 100%;
    border: none;
    border-bottom: 2px solid #ccc;
    padding: 5px 0;
    background-color: transparent;
    outline: none;
  }
  
  .input-container .cos_label {
    position: absolute;
    top: 0;
    left: 0;
    color: #ccc;
    transition: all 0.3s ease;
    pointer-events: none;
    font-size: 14px;
    letter-spacing: 0px;
  }
  
  .input-container input[type="text"]:focus ~ .cos_label{
    top: -18px;
    font-size: 12px;
    color: #333;
    background-color: transparent
  }
  .input-container input[type="text"]:valid ~ .cos_label{
    top: -18px;
    font-size: 12px;
    color: #ccc;
    background-color: white;
  }
  
  .input-container .underline {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 2px;
    width: 100%;
    background-color: #333;
    transform: scaleX(0);
    transition: all 0.3s ease;
  }
  
  .input-container input[type="text"]:focus ~ .underline{
    transform: scaleX(1);
  }

  .input-container input[type="text"]:valid ~ .underline{
    transform: scaleX(1);
  }


  .cos_select{
    font-size: 14px;
    width: 100px;
    border: none;
    border-bottom: 2px solid #ccc;
    padding: 2px 0;
    background-color: transparent;
    outline: none;
  }

  .cos_select-label{
    font-size: 10px;
    position: absolute;
    top: -12px;
    left: 0;
  }

  .cos_option{
    color: black;
  }

  .cos_check_main{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 5px;

  }

  .cos_checkbox_content {
    font-size: 12px;
    /* margin: 0; */
  }

  .cos_checkBox{
    height: 1.2rem;
  }

  .cos_submit_btn{
    width: 100%;
    background: #000000;
    color: #FFFF;
    border-radius: 4px;
    border: none;
    font-size: 14px;
    padding: 0.3rem 0;
}
  .cos_getOtp_btn{
    width: 40%;
    background: #38ACE2;
    color: #FFFF;
    border-radius: 4px;
    border: none;
    font-size: 14px;
    padding: 0.3rem 0;
    margin: 0.6rem auto;
}

.cos_disclaimer{
  font-size: 8px;
  padding: 0px 14px;
  color: #1C1D1F;
}

.cos_disclaimer span {
  font-size: 9px;
  
}
  