.tideaadhardatatitle{
    font-weight: 400;
font-size: 24px;
line-height: 32px;
color: #343434;
}
.tideaadhaarphoto{
    margin-top: 16px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;   
}
.tideaadhharimg{
    border-radius: 50%;
}
.tablelabel{
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.1px;
    color: #0C0C0E;
    margin-top: 15px;
}
.tableinput{
    font-weight: 400;
    font-size: 16px;
    height: 56px;
    border-radius: 8px;
    border: 1px solid #E4E4E7;
    background: #FFFFFF;
    width: 100%;
    text-indent: 15px;
    letter-spacing: 0.25px;
    color: #0C0C0E;
    margin-top: 3px;
    /* box-shadow: 0 0px 8px 0 rgba(0,0,0,0.1); */
    transition: 0.3s;
}
.tableaddressinput{
    font-weight: 400;
    font-size: 16px;
    height: 56px;
    border-radius: 8px;
    border: 1px solid #E4E4E7;
    background: #FFFFFF;
    width: 100%;
    letter-spacing: 0.25px;
    color: #0C0C0E;
    margin-top: 3px;
    /* box-shadow: 0 0px 8px 0 rgba(0,0,0,0.1); */
    transition: 0.3s;
    padding: 15PX;
    overflow-y: auto;
    margin-bottom: 100px;
}

.calendericon{
   position: absolute;
   right: 50px;
   top : 65%
}
.calendericons{
    position: absolute;
   right: 50px;
   top : 47.5%
}
.tidebtns{
    margin-top: 50%;
}

@media (max-width: 575.98px){
.aaharbutton{
    position: fixed;
    bottom:0%;
    width:92%;
    left: 50%;
    z-index: 9999;
    /* padding: 4%; */

}
}