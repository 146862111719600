.headermain{
    margin-top: 30px;
    padding-left: 20px;
    display: flex;
}

.text{
    margin-left: 20px;
    margin-top: 2px;
    /* font-weight: 500; */
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.5px;
    color: #0C0C0E;
    font-weight: bold;
}