.root {
  width: 100vw;
  height: 100vh;
  background: rgba(159, 186, 213, 0.3098039216);
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 1;
}
.root .accessimg-card {
  width: 290px;
  height: auto;
  padding-bottom: 20px;
  background: rgba(255, 255, 255, 0.6);
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
  border-radius: 8px;
  opacity: 1;
  position: relative;
  z-index: 99999;
}
.root .accessimg-card .accessimg {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}
.root .accessimg-card .unblockmsg {
  height: 49px;
  text-align: left;
  font: normal normal 600 16px/25px Poppins;
  font-family: "Poppins", sans-serif;
  letter-spacing: 0.19px;
  color: #254282;
  opacity: 1;
  padding: 15px 34px 0px 29px;
  margin-bottom: 25px;
}
.root .accessimg-card .msglist {
  width: 197px;
  height: 17px;
  text-align: left;
  font: normal normal normal 12px/18px Poppins;
  font-family: "Poppins", sans-serif;
  letter-spacing: 0px;
  color: #333333;
  opacity: 1;
  margin-top: 5px;
}
.root .accessimg-card .buttonheader {
  display: flex;
  justify-content: center;
}
.root .accessimg-card .buttonheader .unblockbutton {
  width: 102px;
  height: 38px;
  background: #254282 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.2392156863);
  border-radius: 6px;
  opacity: 1;
  border: none;
  margin-top: 10px;
}
.root .accessimg-card .buttonheader .unblockbutton span {
  width: 58px;
  height: 20px;
  text-align: center;
  font: normal normal medium 14px/21px Poppins;
  font-family: "Poppins", sans-serif;
  letter-spacing: 0.08px;
  color: #FFFFFF;
  opacity: 1;
}/*# sourceMappingURL=cameraaccess.css.map */