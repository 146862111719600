.ldsring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.ldsring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 80px;
  height: 80px;
  /* margin-right: 30%; */
  border: 8px solid rgb(25, 41, 214);;
  border-radius: 50%;
  -webkit-animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
          animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color:rgb(25, 41, 214) transparent transparent transparent;
}
.ldsring div:nth-child(1) {
  -webkit-animation-delay: -0.45s;
          animation-delay: -0.45s;
}
.ldsring div:nth-child(2) {
  -webkit-animation-delay: -0.3s;
          animation-delay: -0.3s;
}
.ldsring div:nth-child(3) {
  -webkit-animation-delay: -0.15s;  
          animation-delay: -0.15s;
}

@-webkit-keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}