.kycmpmain {
  padding: 20px;
}
.kycmpmain .selfiimg {
    margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;
}

.paragraph {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.25px;
  color: #2f3037;
}

.tideheadermain{
    padding: 10px 0 0 30px;
}
.tideheaderbutton{
    margin-top: 15px;
    border: none;
    background-color: transparent;
    /* width: 21px;
    height: 15px; */margin-bottom: 10px;
}
.tidearrowicon{
     width: 21px;
    height: 15px; 
}
.tideheadertext{
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
    color: #343434;
}

.tickicon{
  width:18px;
  height: 13px;
  margin-top: 6px;
  margin-right: 19px;
}

.textmains{
  display: flex;
  /* margin-bottom: 16px; */
  margin-top: 24px;
  
}
.subtext{
  display: flex;
  margin-top: 24px;
  margin-bottom: 100px;
}