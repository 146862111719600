.root {
  height: 100vh;
  background: #9FBAD5 0% 0% no-repeat padding-box;
  opacity: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.root .root-main {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1607843137);
  border-radius: 12px;
  opacity: 1;
  display: flex;
  margin: 40px;
}
.root .root-main .videokycheader {
  background: #254282 0% 0% no-repeat padding-box;
  border-radius: 12px 0px 0px 12px;
  opacity: 1;
  background-position: center;
  background-repeat: no-repeat;
  padding-left: 60px;
  padding-top: 30px;
  position: relative;
  z-index: 99999;
}
.root .root-main .videokycheader .videokyctext {
  text-align: left;
  font: normal normal 600 35px/53px Poppins;
  font-family: "Poppins", sans-serif;
  letter-spacing: 0px;
  color: black;
  opacity: 1;
}
.root .root-main .videokycheader .QR-note {
  margin-top: 20px;
  text-align: left;
  font: normal normal normal 18px/27px Poppins;
  font-family: "Poppins", sans-serif;
  letter-spacing: 0px;
  color: black;
  opacity: 1;
}
.root .root-main .videokycheader .list {
  margin-top: 12px;
  width: 90%;
  margin-left: 15px;
  text-align: left;
  font: normal normal normal 16px/25px Poppins;
  font-family: "Poppins", sans-serif;
  letter-spacing: 0px;
  color: black;
  opacity: 1;
  line-height: 25px;
  font-size: 18px;
}
.root .root-main .videokycheader .videokycimg {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  margin-right: 30px;
}
.root .root-main .qrheader {
  opacity: 1;
  margin-top: 35px;
  margin-left: 60px;
  margin-right: 60px;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding: 30px;
}
.root .root-main .qrheader .qrtitle {
  height: 28px;
  text-align: left;
  font: normal normal 600 20px/30px Poppins;
  font-family: "Poppins", sans-serif;
  letter-spacing: 0px;
  color: #254282;
  opacity: 1;
  margin-top: 73px;
}
.root .root-main .qrheader .qr {
  opacity: 1;
  margin-top: 23px;
}
.root .root-main .qrheader .qrinfo {
  width: 227px;
  height: 36px;
  text-align: center;
  font: normal normal normal 12px/18px Poppins;
  font-family: "Poppins", sans-serif;
  letter-spacing: 0px;
  color: #333333;
  opacity: 1;
  margin-top: 21px;
}
.root .root-main .qrheader .or {
  margin-top: 15px;
  height: 17px;
  text-align: center;
  font: normal normal normal 12px/18px Poppins;
  letter-spacing: 0px;
  color: #999999;
  font-family: "Poppins", sans-serif;
}
.root .root-main .qrheader .copybutton {
  width: 113px;
  height: 38px;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.2392156863);
  border-radius: 6px;
  opacity: 1;
  margin-top: 12px;
  border: none;
  cursor: pointer;
}
.root .root-main .qrheader .copybutton:hover {
  font-weight: 900;
}
.root .root-main .qrheader .copybutton span {
  height: 20px;
  font: normal normal medium 14px/21px Poppins;
  letter-spacing: 0px;
  color: #FFFFFF;
  opacity: 1;
  font-family: "Poppins", sans-serif;
}/*# sourceMappingURL=largescreen.css.map */