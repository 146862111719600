  .clock{ 
    border-radius: 70px;
    border: 2px solid #1976D2;
    position: relative;
    /* top: 50%;
    left: 50%; */
    /* margin-left: -50px;
    margin-top: -50px; */
    display: block;
    width: 30px;
    height: 30px;
    margin-right: 4px;
  }
  
  .clock:after{
      content: "";
      position: absolute;
      background-color: #1976D2;
      top: 50%;
      left: 50%;
      height: 45%;
      width: 2px;
      border-radius: 5px;
      /* -webkit-transform-origin: 50% 97%; */
              transform-origin: 50% 0%;
      -webkit-animation: grdAiguille 2s linear infinite;
              animation: grdAiguille 2s linear infinite;
  }
  .timer-wrapper{
    padding-left: 10px;
    letter-spacing: 1px;
    font-weight: bold;
  }
  
  @-webkit-keyframes grdAiguille{
      0%{-webkit-transform:rotate(0deg);}
      100%{-webkit-transform:rotate(360deg);}
  }
  
  @keyframes grdAiguille{
      0%{transform:rotate(0deg);}
      100%{transform:rotate(360deg);}
  }
  
  .clock:before{
      content: "";
      position: absolute;
      background-color: #1976D2;
      /* top: 24px;
      left: 48%; */
      height: 30%;
      width: 2px;
      top: 50%;
      left: 50%;
      /* transform: translate(-50%, -50%); */
      border-radius: 5px;
      /* -webkit-transform-origin: 50% 94%; */
              transform-origin: 50% 0%;
      -webkit-animation: ptAiguille 12s linear infinite;
              animation: ptAiguille 12s linear infinite;
  }
  
  @-webkit-keyframes ptAiguille{
      0%{-webkit-transform:rotate(0deg);}
      100%{-webkit-transform:rotate(360deg);}
  }
  
  @keyframes ptAiguille{
      0%{transform:rotate(0deg);}
      100%{transform:rotate(360deg);}
  }