.arrowbutton{
    border: none;
    background-color: transparent;
    margin-top: 20px;
}

.userconsenttext{
    padding: 20px;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: #F1F3F4;
    z-index: 99;
    overflow-y: auto;
    
}
.userauthorisation{
    color: #343434;
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
    margin-top: 24px;
    border-bottom: none;
    margin-bottom: 24px;

}

.tickicon{
    width:18px;
    height: 13px;
    margin-top: 6px;
    margin-right: 19px;
}

.textmains{
    display: flex;
    margin-bottom: 16px;
    
}
.textmainss{
    display: flex;
    /* margin-bottom: 16px; */
    margin-top: 24px;
}

.textmains:nth-last-child(1){
    margin-bottom: 200px;
}
.hometext{
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.25px;
    color: #2F3037
}

.tidecardmain{
    display: flex;
    flex-direction: column;
    /* justify-content: space-between;
    align-items: space-between; */
    /* height: 100%; */
    position: fixed;
    bottom: 0%;
    padding-bottom: 5%;
    padding-right: 20px;
    background: transparent;
}

.tidecard{
    /* margin-top: 50px; */
    display: flex;
    border: 1px solid #C8C9D0;;
    background: #FFFFFF;
    transition: 0.3s;
    border-radius: 8px;
    padding: 10px;
    margin-bottom: 15px;
}

.checkbox{
    margin-right: 17px;
    width: 20px;
    height: 20px;
    margin-top: 6px;
}