.camera-access-container {
    width: 100vw;
    height: calc(100vh-120px);
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: center; */
    background: #f5f7fa;
    text-align: center;
    font-family: "Poppins", sans-serif;
    overflow-y: auto;
  }
  
  .title {
    font-size: 28px;
    font-weight: 600;
    color: #343434;
    line-height: 28px;
  }
  
  .description {
    font-size: 16px;
    color: #343434;
    margin-bottom: 20px;
    max-width: 90%;
  }
  
  /* ICONS CONTAINER */
  .icons-container {
    display: flex;
    gap: 20px;
    margin-bottom: 20px;
  }
  
  .icon-item {
    position: relative;
    width: 65px;
    height: 65px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #dde8ff;
    border: 2px solid #a4bcff;
    border-radius: 50%;
    padding: 10px;
  }
  
  .icon-item img {
    width: 40px;
    height: 40px;
    filter: invert(24%) sepia(92%) saturate(747%) hue-rotate(210deg) brightness(97%) contrast(96%);
  }
  
  /* ERROR ICON */
  .error-icon {
    position: absolute;
    top: -5px;
    right: -5px;
    width: 30px;
    height: 30px;
    background: #fce8e6;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .error-icon img {
    width: 16px;
    height: 16px;
  }
  
  /* TEXT BELOW ICON */
  .icon-item p {
    font-size: 12px;
    margin-top: 5px;
    color: #343434;
  }
  
  /* INSTRUCTION HEADER & LIST */
  .instruction-header {
    font-size: 16px;
    font-weight: 400;
    color: #343434;
    text-align: left;
    width: 80%;
  }
  
  .instruction-follow {
    font-size: 14px;
    font-weight: 600;
    color: #343434;
    text-align: left;
    width: 80%;
    margin-top: 10px;
  }
  
  /* NUMBERED LIST */
  .instructions-list-tide {
    text-align: left;
    max-width: 80%;
    font-size: 14px;
    color: #444;
    margin-top: 10px;
    padding-left: 20px;
  }
  
  .instructions-list-tide li {
    margin-bottom: 8px;
    line-height: 20px;
    
  }
  .instructions-list-tide li:last-child {
    margin-bottom: 120px;
}
  
  /* BOLD SPECIFIC WORDS */
  .instructions-list-tide li b {
    font-weight: 600;
  }
  
  /* BUTTON */
  .button-container {
    margin-top: 20px;
    position: fixed;
    bottom: 0%;
    left: 50%;
    transform: translate(-50%, 0);
    width: 100%;
    height: 70px;
    background: #f5f7fa;
}
  
  .access-button {
    background: #254282;
    color: white;
    border: none;
    padding: 12px 24px;
    font-size: 14px;
    border-radius: 6px;
    cursor: pointer;
    transition: background 0.3s;
  }
  
  .access-button:hover {
    background: #1d3466;
  }
   